<template>
    <site-header :phone="phone"></site-header>
    <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
    <div class="bg-[#f6f7f8] pb-36">
        <div class="container">
            <h1 class="w-2/3 mx-auto pt-8 sm:hidden">Самый короткий путь к офферу в&nbsp;Тинькофф</h1>
            <h1 class="mx-auto pt-8 sm:block hidden text-2xl">Пройдите все этапы интервью в&nbsp;ускоренном режиме</h1>
            <div class="mt-4 text-center sm:hidden">Пройдите все этапы интервью в ускоренном режиме и присоединяйтесь к команде</div>
            <div class="mt-4 text-center hidden sm:block">Если нужного направления нет в списке — оставьте предварительную заявку в форме ниже</div>
            <div class="mt-8 text-center mb-12">
                <yellow-button @click="gotoSpecs">Выбрать направление</yellow-button>
                <transparent-button class="ml-4 sm:ml-0 text-blue-500" @click="gotoForm">Оставить предзаявку</transparent-button>
            </div>
            <img class="sm:hidden" src="@/assets/images/top.webp" alt="top">
            <img class="hidden sm:block" src="@/assets/images/top-sm.webp" alt="top">

            <div id="specs-block"/>
            <h2 class="sm:hidden w-2/3 mx-auto mt-12">Выбирайте направление и приходите на fast-интервью</h2>
            <div class="sm:hidden mt-6 w-3/4 text-center mx-auto">Даты мероприятий могут поменяться. Если нужного направления нет в списке, оставьте предзаявку в форме ниже. По всем вопросам пишите на <a class="text-blue-600"
                                                                                                                                                                                                            href="mailto:one-day-offer@tinkoff.ru">one-day-offer@tinkoff.ru</a>
            </div>
            <div class="flex justify-center mt-6">
                <button
                    class="py-3 border-b border-solid border-[#E3E4E5] hover:border-b-[3px]"
                    :class="{ 'border-[#FFDE32] border-b-[3px]': eventSelectorOpen, 'hover:border-[#C7C9CC]': !eventSelectorOpen }"
                    @click="eventSelectorOpen = true"
                >Открытые
                </button>
                <div class="py-3 border-b border-solid border-[#E3E4E5]">&emsp;&ensp;</div>
                <button
                    class="py-3 border-b border-solid border-[#E3E4E5] hover:border-b-[3px]"
                    :class="{ 'border-[#FFDE32] border-b-[3px]': !eventSelectorOpen, 'hover:border-[#C7C9CC]': eventSelectorOpen }"
                    @click="eventSelectorOpen = false"
                >Завершенные
                </button>
            </div>
            <splide :options="slider" class="mt-12">
                <splide-slide
                    v-for="event in eventsArray()"
                    :key="event.start"
                    class="box-border bg-white rounded-3xl max-h-full hover:scale-105 transition-transform duration-1000"
                >
                    <a :href="getParamUrl(event.link)">
                        <div class="flex p-6 flex-col h-full">
                            <div class="text-[#A4A4A4] mb-3">{{ dates2string(event.start) }}</div>
                            <h3>{{ event.title }}</h3>
                            <div class="mt-3" v-html="event.text"/>
                            <div class="flex-grow"></div>
                            <div class="px-4 py-2 block bg-[#FFDD2D] rounded-lg w-fit mt-6" v-if="event.link.includes('tinkoff.ru')">Участвовать</div>
                            <div v-if="!event.link.includes('tinkoff.ru')" class="text-blue-500">Следить за анонсами</div>
                        </div>
                    </a>
                </splide-slide>
            </splide>

            <h2 class="mt-24 sm:text-2xl sm:mx-2">Предзаявка
                <nobr>на fast-интервью</nobr>
            </h2>
            <div class="w-2/3 text-center mx-auto mt-4 sm:hidden">
                Выберите специализацию и оставьте заявку, чтобы мы могли связаться с вами. Если мероприятий по вашему направлению пока не планируется, мы напишем и предложим подходящие вакансии
            </div>
            <div class="w-3/4 text-center mx-auto mt-4 sm:block hidden">
                Заполните форму, и мы напишем, чтобы рассказать о ближайшем ивенте или предложить открытые вакансии
            </div>
        </div>

        <frame-form url="https://one-day-offer.tinkoff.ru/form/" class="mt-6"/>

        <div class="container">
            <h2 class="sm:text-2xl sm:mx-2 mt-16">В какие проекты приглашаем</h2>
            <div class="text-center mt-4">Всего более 50 продуктов, которыми пользуются 30&nbsp;млн клиентов. Вот некоторые из них</div>
            <div class="grid gap-12 sm:gap-6 grid-cols-2 mt-10 sm:grid-cols-1">
                <div v-for="prj in projects" :key="prj.title" class="bg-white p-8 rounded-3xl">
                    <h3>{{ prj.title }}</h3>
                    <div class="mt-4">{{ prj.text }}</div>
                </div>
            </div>

            <h2 class="mt-16">Как мы работаем</h2>
            <div class="grid grid-cols-3 sm:grid-cols-1 gap-10 mt-10">
                <div class="sm:order-1">
                    <div class="mt-6 sm:mt-0 sm:p-6 sm:bg-[#EAECEE] sm:rounded-3xl">
                        <h3>Простор для самореализации</h3>
                        <div class="mt-3">Можно выбрать интересный проект и&nbsp;реализовать свой потенциал вместе с&nbsp;командой</div>
                    </div>
                    <div class="mt-6 sm:p-6 sm:bg-[#EAECEE] sm:rounded-3xl">
                        <h3>Влияние на продукт</h3>
                        <div class="mt-3">Сегодня это ваша идея, завтра — конкретное улучшение в продукте или процессах</div>
                    </div>
                </div>
                <img src="@/assets/images/how-to.webp" alt="howto" class="col-span-2 sm:col-span-1 sm:order-0 bg-[#EAECEE] rounded-3xl">
                <div class="p-6 bg-[#EAECEE] rounded-3xl sm:order-1">
                    <h3>Профессиональный рост</h3>
                    <div class="mt-3">Можно выбрать вектор развития и прокачивать скиллы в нужном направлении. Рост может быть как горизонтальным, так и вертикальным</div>
                </div>
                <div class="p-6 bg-[#EAECEE] rounded-3xl sm:order-1">
                    <h3>Минимум бюрократии</h3>
                    <div class="mt-3">Работаем гибко, стараемся, чтобы путь от идеи до ее реализации был максимально коротким</div>
                </div>
                <div class="p-6 bg-[#EAECEE] rounded-3xl sm:order-1">
                    <h3>26 офисов</h3>
                    <div class="mt-3">Работаем в офисе или в гибридном формате — в 21 городе России, а также в Беларуси, Армении и Казахстане</div>
                </div>
            </div>

            <h2 class="mt-16">Как проходит
                <nobr>ИТ-собеседование</nobr>
            </h2>
            <a :href="'https://www.tinkoff.ru/career/it/interview/'+urlParams">
                <div class="bg-[#EAECEE] rounded-3xl flex sm:flex-col justify-between mt-10 hover:scale-105 transition-transform duration-1000">
                    <div class="flex flex-col min-h-full w-1/3 sm:w-full p-8">
                        <h3 class="text-3xl font-medium">Процесс интервью</h3>
                        <div class="mt-2">
                            Рассказываем, из&nbsp;каких этапов состоит собеседование на&nbsp;<nobr>ИТ-вакансию</nobr>
                            и&nbsp;как к&nbsp;нему подготовится
                        </div>
                        <div class="flex-grow"></div>
                        <div class="bg-white rounded-md px-6 py-2 w-fit sm:hidden">Узнать больше</div>
                    </div>
                    <img class="w-2/5 sm:w-full" src="@/assets/images/interview.png" alt="iview">
                </div>
            </a>

            <h2 class="mt-16 sm:text-2xl">Что еще мы предлагаем</h2>
            <div class="grid grid-cols-3 sm:grid-cols-1 sm:gap-6 gap-12 mt-12">
                <a :href="'https://meetup.tinkoff.ru/'+urlParams">
                    <div class="bg-white p-6 rounded-3xl flex flex-col hover:scale-105 h-full transition-transform duration-1000">
                        <img src="@/assets/images/i1.svg" alt="i1" class="h-8 mr-auto">
                        <h3 class="mt-8">Митапы</h3>
                        <div class="mt-2">
                            Обмениваемся знаниями с экспертами других компаний и делимся опытом
                        </div>
                        <div class="flex-grow h-8"></div>
                        <div class="text-blue-500">Узнать больше</div>
                    </div>
                </a>
                <a :href="'https://fintech.tinkoff.ru/'+urlParams">
                    <div class="bg-white p-6 rounded-3xl flex flex-col hover:scale-105 h-full transition-transform duration-1000">
                        <img src="@/assets/images/i2.svg" alt="i2" class="h-8 mr-auto">
                        <h3 class="mt-8">Обучение</h3>
                        <div class="mt-2">
                            Читаем курсы для разработчиков и студентов
                        </div>
                        <div class="flex-grow"></div>
                        <div class="text-blue-500">Узнать больше</div>
                    </div>
                </a>
                <a href="https://habr.com/ru/company/tinkoff/blog/">
                    <div class="bg-white p-6 rounded-3xl flex flex-col hover:scale-105 h-full transition-transform duration-1000">
                        <img src="@/assets/images/i3.svg" alt="i3" class="h-8 mr-auto">
                        <h3 class="mt-8">Блог на Хабре</h3>
                        <div class="mt-2">
                            Пишем на Хабре о внутренней кухне и интересных разработках
                        </div>
                        <div class="flex-grow"></div>
                        <div class="text-blue-500">Узнать больше</div>
                    </div>
                </a>
            </div>

            <h2 class="mt-16 sm:text-2xl">Подписывайтесь на IT’s Tinkoff</h2>
            <splide :options="socSlider" class="mt-8">
                <splide-slide v-for="soc in socials" :key="soc.link">
                    <a :href="soc.link" target="_blank">
                        <div class="sm:bg-transparent bg-white p-4 rounded-2xl text-center">
                            <img :src="soc.icon" alt="soc" class="h-14 mx-auto mt-4 mb-6">
                            <h3 class="sm:text-sm sm:font-light">{{ soc.title }}</h3>
                        </div>
                    </a>
                </splide-slide>
            </splide>
        </div>
    </div>
    <site-footer :phone="phone" disclaimer="" copyright="АО «Тинькофф Банк», официальный сайт, универсальная лицензия ЦБ РФ № 2673" :bank-links="bottomLinks"></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header";
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from "@/components/yellow-button.vue";
import TransparentButton from "@/components/transparent-button.vue";
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import FrameForm from "@/components/frame-form.vue";


export default {
    name: 'App',
    components: {FrameForm, TransparentButton, YellowButton, GradientDelimiter, SiteHeader, SiteFooter, Splide, SplideSlide},

    data: () => ({
        phone: process.env.VUE_APP_PHONE,
        eventSelectorOpen: true,
        socials: [
            {icon: require('@/assets/images/itst.svg'), title: 'IT\'s Tinkoff', link: 'https://t.me/+4Z3KnU2eMJQzZDUy'},
            {icon: require('@/assets/images/yt.svg'), title: 'Ютуб-канал', link: 'https://www.youtube.com/c/ITsTinkoff/'},
            {icon: require('@/assets/images/itst-crew.svg'), title: 'IT’s Tinkoff Crew', link: 'https://t.me/+K_k5FZrKUXQ3ZDhi'},
            {icon: require('@/assets/images/habr.svg'), title: 'Блог на Хабре', link: 'https://habr.com/ru/companies/tinkoff/articles/'},
            {icon: require('@/assets/images/vk.svg'), title: 'ВКонтакте', link: 'https://vk.com/itstinkoff/'},
        ],
        projects: [
            {
                title: 'Мобильный банк',
                text: 'Развиваем лучший мобильный банк в мире по версии Global Finance — Best Digital Bank Award 2020'
            },
            {
                title: 'Тинькофф Бизнес',
                text: 'Делаем ведение бизнеса комфортным: оформляем отчетность онлайн без поездок в налоговую'
            },
            {
                title: 'Тинькофф Страхование',
                text: 'Меняем страховой рынок — разрабатываем и улучшаем процессы продаж страховых продуктов, обслуживания, урегулирования'
            },
            {
                title: 'Тинькофф Касса',
                text: 'Развиваем сервис онлайн-платежей для бизнеса. Наша цель — предоставить клиентам удобный интерфейс для управления платежами, а покупателям — возможность оплачивать все быстро и просто'
            },
            {
                title: 'Тинькофф Инвестиции',
                text: 'Собираем инвестиционные портфели с помощью советника и делаем инвестирование доступным каждому'
            },
            {
                title: 'Платежные системы',
                text: 'Создаем безопасное пространство для любых финансовых операций. Работаем с машинным обучением, антиспамом и системами безопасности'
            },
        ],
        events: [
            {
                title: 'Java Weekend Offer',
                text: 'Для Java-разработчиков с опытом от 3 лет',
                link: '',
                start: '2023-02-04',
            },
            {
                title: 'Data Weekend Offer',
                text: 'Для Data-инженеров с опытом от 3 лет, ETL-разработчиков с опытом от 2 лет и системных аналитиков DWH с опытом от 2 лет',
                link: '',
                start: '2023-03-11',
            },
            {
                title: 'Mobile Weekend Offer',
                text: 'Для Mobile-разработчиков с опытом от 3 лет',
                link: '',
                start: '2023-03-25',
            },
            {
                title: 'Analyst Week Offer',
                text: 'Для бизнес-аналитиков с опытом от 1 года и продуктовых и системных аналитиков с опытом от 2 лет',
                link: '',
                start: '2023-04-29',
            },
            {
                title: 'Go Weekend Offer',
                text: 'Для Go-разработчиков с опытом от 3 лет',
                link: '',
                start: '2023-06-03',
            },
            {
                title: 'Product Manager Week Offer',
                text: 'Приглашаем менеджеров продукта с опытом от 2 лет',
                link: 'https://one-day-offer.tinkoff.ru/product-management/',
                start: '2023-08-12',
            },
            {
                title: 'Java+Scala Weekend Offer',
                text: 'Приглашаем Java-разработчиков с&nbsp;опытом от&nbsp;3&nbsp;лет и&nbsp;Scala-разработчиков с&nbsp;опытом от&nbsp;1,5&nbsp;лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-08-16',
            },
            {
                title: 'Mobile Weekend Offer',
                text: 'Приглашаем Mobile-разработчиков с&nbsp;опытом от&nbsp;3&nbsp;лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-09-16',
            },
            {
                title: 'Design Weekend Offer',
                text: 'Приглашаем продуктовых дизайнеров с опытом от 3 лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-10-07',
            },
            {
                title: 'SRE Weekend Offer',
                text: 'Приглашаем SRE-инженеров с&nbsp;опытом от&nbsp;3&nbsp;лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-10-28',
            },
            {
                title: 'System analyst Weekend Offer',
                text: 'Приглашаем системных аналитиков с опытом от 2 лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-11-18',
            },
            {
                title: 'Java Weekend Offer',
                text: 'Приглашаем Java-разработчиков с&nbsp;опытом от&nbsp;3&nbsp;лет',
                link: 'https://t.me/+ik8B1Z55wvlmYmVi',
                start: '2023-12-09',
            },
        ],
        slider: {
            rewind: false,
            perPage: 3,
            perMove: 2,
            gap: 48,
            pagination: true,
            breakpoints: {
                480: {
                    perPage: 1,
                    pagination: true,
                    arrows: false,
                }
            }
        },

        socSlider: {
            rewind: false,
            perPage: 4,
            perMove: 3,
            gap: 40,
            pagination: false,
            breakpoints: {
                480: {
                    perPage: 3,
                    pagination: true,
                    arrows: false,
                    gap: 20,
                }
            }
        },
        urlParams: '',
        bottomLinks: [],
    }),

    methods: {
        getParamUrl(url) {
            if (url.includes('tinkoff.ru')) {
                return url + this.urlParams;
            }
            return url;
        },

        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        gotoSpecs() {
            window.scrollTo({
                top: document.getElementById('specs-block').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        dates2string(date) {
            const monthNames = [
                "января", "февраля", "марта",
                "апреля", "мая", "июня",
                "июля", "августа", "сентября",
                "октября", "ноября", "декабря"
            ];
            let dte = new Date(date);
            let d = dte.getDate();
            let str = d.toString() + '—' + (d + 1).toString() + ' ';
            return str + monthNames[dte.getMonth()];
        },
        eventsArray() {
            Date.prototype.addDays = function (days) {
                let date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }

            let arr = [];
            let now = new Date();
            if (this.eventSelectorOpen) {
                this.events.forEach(function (v) {
                    console.log('now = ' + now.toString());
                    let d = new Date(v.start).addDays(1);
                    console.log('d = ' + d.toString());
                    if (now <= d) {
                        arr.push(v);
                    }
                });
            } else {
                this.events.forEach(function (v) {
                    let d = new Date(v.start).addDays(1);
                    if (now > d) {
                        arr.push(v);
                    }
                });
                arr = arr.reverse();
            }

            return arr;
        },
    },
    created() {
        this.urlParams = window.location.search;
        this.bottomLinks = [
            {text: 'Работа в IT', link: 'https://www.tinkoff.ru/career/it/about/' + this.urlParams},
            {text: 'Бизнес и процессы', link: 'https://www.tinkoff.ru/career/back-office/about/' + this.urlParams},
            {text: 'Работа с клиентами', link: 'https://www.tinkoff.ru/career/service/' + this.urlParams},
        ];
    },
    mounted() {
        setTimeout(function () {
            window.scrollTo({
                top: document.getElementById('specs-block').offsetTop,
                left: 0,
                behavior: "smooth",
            });

        }, 500);
    }

}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 13px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 700;
    font-variant-ligatures: no-contextual;
    font-size: 38px;
    line-height: 44px;
    letter-spacing: 0;
    text-align: center;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 35px;
    }
}

h2 {
    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h3 {
    font-size: 22px;
    line-height: 26px;
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.splide__pagination {
    bottom: -30px;

    li {
        button {
            background: #DDDFE0;

            &.is-active {
                background: #FFDD2D;
            }
        }
    }
}

.splide__arrow {
    background: #eee;

    svg {
        fill: #336fee;
    }

    &:disabled {
        display: none;
    }

    &.splide__arrow--next {
        right: -3em;
    }

    &.splide__arrow--prev {
        left: -3em;
    }
}

</style>
