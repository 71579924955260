<template>
    <iframe v-if="url" width="100%" height="680" :src="returnUrl" scrolling="no" id="form-iframe"/>
</template>

<script>
export default {
    name: "frame-form",
    props: {
        url: {
            type: String,
            default: '',
        }
    },
    computed: {
        returnUrl: function () {
            return this.url + '?parent_url=' + encodeURIComponent(document.location.href);
        }
    },
    created: function () {
        window.addEventListener('message', function (event) {
            if (typeof event.data !== 'string') {
                return;
            }

            try {
                let evdata = JSON.parse(event.data);
                switch (evdata.type) {
                    case 'appFramePageHeight':
                        // eslint-disable-next-line no-case-declarations
                        let h = evdata.result.height;
                        document.getElementById('form-iframe').style.height = (h + 10) + "px";
                        break;
                    case 'analytics':
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({'event': evdata.event});
                        break;
                }
            } catch (e) {
                // noinspection UnnecessaryReturnStatementJS
                return;
            }

        }, false);
    }

}
</script>

<style scoped>

</style>